import React from "react";
import { connect } from "react-redux";

import { loadPark, loadGEO, select360Photo } from "../store/reducers/Park";
import { photoSelector } from "../selectors/Park";

import ParkHeader from "./ParkHeader";
import TripVisits from "./TripVisits";
import Activities from "./Activities";
import PhotoGallery  from "../album/PhotoGallery";
import Three60PhotoList from "../pano/Three60PhotoList";
import ParkMap from "./ParkMap";

import "./Park.css";
import "./Responsive.Park.css";

export class Park extends React.Component {

	static displayName = "Park";

	static defaultProps = {
		photoList:[],
		three60:[],
		name:"",
		selected360:-1,
	};

	componentWillMount(){
		var { props } = this;

		props.loadPark(props.match.params.park);
		props.loadGEO(props.match.params.park);
	}

	render(){
		var { props } = this;
		return (
			<section className="park-details"
			itemScope
			itemType="https://schema.org/Park"
			itemProp="park">
				<ParkHeader />
				<div className="trip-info">
					<TripVisits />
					<Activities name={props.name} />
				</div>
				<PhotoGallery photoList={props.photoList} />
				<Three60PhotoList
					photoList={props.three60}
					onPhotoSelection={props.onSelected360Photo}
					selected360={props.selected360}
				/>
				<ParkMap name={props.name} />
			</section>
		);
	}

}

function mapStateToProps(state, ownProps){
	var photoList = photoSelector(state);
	return {
		photoList:photoList.normal,
		three60:photoList.three60,
		name:state.park.name,
		selected360:state.park.selected360,
	};
}

function mapDispatchToProps(dispatch, ownProps){
	return {
		loadPark(parkName){
			return dispatch(loadPark(parkName));
		},
		loadGEO(parkName){
			// dispatch(loadGEO(parkName, "Waypoints"));
			return dispatch(loadGEO(parkName));
		},
		onSelected360Photo(index){
			return dispatch(select360Photo(index));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Park);