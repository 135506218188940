import React from "react";
import { Author } from "../utils/common";
import Micro from "../utils/Micro";
import Rating from "./Rating";

import Activity from "./Activity";
import "./ReviewDay.css";

export default function ReviewDay(props){
	var { review, rating, name, activities } = props
		, micro = [Author
			, { itemProp:"inLanguage", content:"English" }
			, { itemProp:"keywords", content:`review, National Park, America, ${name}, NPS, vacation, USA` }
		]
		;

	return (
		<article className="review-day" itemScope itemProp="review" itemType="https://schema.org/Review">
			<div itemProp="reviewBody" className="review-body">
				{review.split(/\\n|\n/).map((v,i) => <p key={i}>{v}</p> )}
			</div>
			<footer className="day-rating" itemProp="reviewRating">
				<div className="day-activities">
					<p>The main activities we took today include:</p>
					<div className="day-activity-list flex">
						{activities.map((aa) => {
							return <Activity key={aa.name} {...aa} />
						})}
					</div>
				</div>
				<div className="rating-holder">
					<p>Overall, I give this day a rating of:</p>
					<Rating value={rating} />
				</div>
			</footer>
			<Micro micro={micro} />
		</article>
	);
}

ReviewDay.defaultProps = {
	review:"",
	rating:0,
	name:"",
	activities:[],
};
