
import React from 'react';
import { connect } from 'react-redux';
import Helmet from "react-helmet";
// import { push } from "react-router-redux";
// import Scroll from "react-scroll";

// import { stripURL } from "../../routes/Router";
import { setMapZoon, setArrayCenter } from "../store/reducers/Map";
import { loadUSAParks, loadParkWaypoints } from '../store/reducers/USA';
import { getParkMarkers } from "../selectors/Park";

import { Author, License } from "../utils/common";
import Micro from "../utils/Micro";
import MapContainer, { USA_CENTER } from '../map/MapContainer';
import MyStats from './MyStats';
import ParkMarker from "./ParkMarker";

import "./USA.css"

export class USA extends React.Component {

	static defaultProps = {
		parks:            null,
		currentParkCount: 27,
		selectedPark:null,
		geoJSON:null,
	};

	componentWillMount(){
		var me = this;

		// if(!me.props.parks){
		// } else {
		// }
		me.props.loadUSAParks();
		me.props.setMapZoon(4);
		me.props.setMapCenter(USA_CENTER);
		// me.props.loadMarkers();
	}

	componentWillReceiveProps(nextProps){
		var props = this.props;

		if(props.selectedPark !== nextProps.selectedPark){
			if(nextProps.selectedPark){
				props.loadPreview(nextProps.selectedPark.name, nextProps.selectedPark.mapName);
			}
		}
	}

	_renderMicro = () => {
		var { markers } = this.props;

		return markers.map(v => {
			let mm = [{
						itemProp:"name",
						content:v.name,
				},{
					itemType:"https://schema.org/GeoCoordinates",
					itemProp:"geo",
					child:[{
						itemProp:"latitude",
						content:v.position[0]
					},{
						itemProp:"longitude",
						content:v.position[1],
					},{
						itemProp:"addressCountry",
						content:"United States of America",
					}],
				}];

			if(v.photo){
				mm.push({
					itemProp:"photo",
					itemType:"https://schema.org/Photograph",
					child:[{
						itemProp:"name",
						content:v.photo.name
					}, Author, License]
				});
			}

			return (
				<div className="hidden" key={v.name} itemScope itemType="https://schema.org/Place">
					<Micro micro={mm} />
				</div>
			);
		});
	}

	render() {
		return (
			<article className="state-component usa-page" itemScope itemType="https://schema.org/CreativeWork">
				<Helmet>
					<title>All of the Parks, and the ones I've been to</title>
					<meta name="description" content={`It is my goal to get to visit each and every one of the over 60 National Parks that make up the system.  Currently, I've made it to {this.props.currentParkCount} of them`} />
					<meta property="og:type" content="website" />
					<meta property="og:url" content="https://steve.deadlycomputer.com/america/" />
					<meta property="og:image" content="https://static.alphavega.com/images/svgs/Flag_of_the_United_States.svg" />
					<meta property="og:image:width" content="200" />
					<meta property="og:image:height" content="200" />
					<meta property="og:image:alt" content="Flaf of USA" />
				</Helmet>
				<header className="state-header usa-header">
					<h2 itemProp="headline">National Parks of the United States</h2>
					<div className="state-desc" itemProp="description">
						<p>The National Parks System is one of the many things that makes America Great.  Founded in 1916, the National Park Service (<abbr title="National Park Service">NPS</abbr>) protects some of the rarest, most awe-inspiring, wonderful bits of land in the world.</p>
						<p>It is my goal to get to visit each and every one of the 60 National Parks that make up the system.  Currently, I've made it to {this.props.currentParkCount} of them, going back to my first official trip to The Grand Canyon in 2009.  Every year I try to get to a couple of new ones, and some older ones, every time you go you see something new, and experience something better.</p>
					</div>
				</header>
				<section className="state-body usa-body">
					<MapContainer
						mapName         = "USA_MAP"
						nav             = "america"
						hasGeoJSON={this.props.geoJSON}
						hasMarkers={this.props.markers}
						markerComponent={ParkMarker}
					/>
					<MyStats />
				</section>
				<footer className="usa-footer" itemProp="disambiguatingDescription">
					<p>In addition to the 59 National Parks, there are another 300+ units admiterstrated by the <span>NPS</span>.  They encompess Memorials, Monuments, Historic Sites, Lake & Seashores, Battlefields, and others.  I would love to visit as many of them as possible, but I am focusing my attention on the best of the best, the Parks.</p>
				</footer>
				{this._renderMicro()}
			</article>
		);
	}
}

function mapStateToProps(state){
	return {
		parks:state.usa.parks,
		markers:getParkMarkers(state),
		// geoJSON:state.usa.geoJSON,
		selectedPark: state.map.selectedMarker,
	};
}

function mapDispatchToProps(dispatch, ownProps){
	return {
		loadUSAParks(){
			return dispatch(loadUSAParks());
		},
		setMapZoon(zoom){
			return dispatch(setMapZoon(zoom));
		},
		setMapCenter(center){
			return dispatch(setArrayCenter(center));
		},
		loadMarkers(){
			return dispatch(loadParkWaypoints());
		},
		loadPreview(name, mapName){
			// dispatch(push(`/america/park/${stripURL(name)}/`));
			// Scroll.scroller.scrollTo(`Leaflet-${mapName}`, {
			// 	duration: 300,
			// 	smooth:true,
			// });
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(USA);