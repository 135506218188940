import React from 'react';
import { connect } from 'react-redux';
import { ACT_URL } from "../nps/Activities";
import NPSLogo from "../nps/NPSLogo";
import { loadUSAStats } from '../store/reducers/USA';
import "./MyStats.css";

export class MyStats extends React.Component {

	static defaultProps = {
		stats: null
	};

	componentDidMount(){
		if(!this.props.stats){
			this.props.loadParkStats();
		}
	}

	_renderStats = () => {
		var me = this
			, stats = me.props.stats
			;

		if(!stats){ return null; }

		return [
			(<div className="park-activity" key="total">
				<h3>{stats["Total Parks"]}/59</h3>
				<h4>Parks</h4>
				<NPSLogo />
			</div>)
			, (<div className="park-activity" key="sunrises" itemScope itemType="http://schema.org/Thing">
				<h3 itemProp="name">{stats.Sunrises} Sunrises</h3>
				<img src={`${ACT_URL}/Sunrise-Sunset_Mountains.svg`} alt="Camping Icon" title="Camping" itemProp="image" />
			</div>)
			, (<div className="park-activity" key="camped" itemScope itemType="http://schema.org/Thing">
				<h3>{stats.Camped} nights</h3>
				<h4 itemProp="name">Camped</h4>
				<img src={`${ACT_URL}/camper.svg`} alt="Camping Icon" title="Camping" itemProp="image" />
			</div>)
			, (<div className="park-activity" key="hiked" itemScope itemType="http://schema.org/PhysicalActivity">
				<h3>{stats.Hiked} miles</h3>
				<h4 itemProp="name">Hiked</h4>
				<img src={`${ACT_URL}/hiking.svg`} alt="Hiking Icon" title="Hiking" itemProp="image" />
			</div>)
			, (<div className="park-activity" key="sunsets" itemScope itemType="http://schema.org/Thing">
				<h3 itemProp="name">{stats.Sunsets} Sunsets</h3>
				<img src={`${ACT_URL}/Sunrise-Sunset_Mountains.svg`} alt="Camping Icon" title="Camping" itemProp="image" />
			</div>
		)];
	}

	render(){
		return (
			<article className="park-stats container">
				<header>
					<h2>My Statisitcs</h2>
					<p>Throughout my many individual visits, I have covered the following milestones.  While these stats don't cover every mile of hiking I did, or every sunrise and sunset I saw, they do give an overview of my progress and the memories I've made throughout.</p>
				</header>
				<section className="park-boxes">
					{this._renderStats()}
				</section>
			</article>
		);
	}
}

function mapStateToProps(state){
	return {
		stats:state.usa.stats,
	};
}

function mapDispatchToProps(dispatch){
	return {
		loadParkStats(){
			return dispatch(loadUSAStats());
		}
	};
}


export default connect(mapStateToProps, mapDispatchToProps)(MyStats);